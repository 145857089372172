import {TournamentMapData} from '@model/tournament/map-tournament-data';

export class MapTournament {

    constructor(
        public id: number,
        public name: string,
        public status: string,
        public max_players: number,
        public reserve_players: number,
        public players: number,
        public end_of_applications: string,
        public court_name: string,
        public type: string,
        public start_date: string
    ) {
    }
    court_tournaments: number;

    static buildBasedOnTournamentMapData(value: TournamentMapData) {
        return new MapTournament(
            +value.id,
            value.name,
            value.status,
            +value.max_players,
            +value.reserve_players,
            +value.players,
            value.end_of_applications,
            value.court_name,
            value.type,
            value.start_date
        );
    }
}

