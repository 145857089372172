import {Pipe, PipeTransform} from '@angular/core';
import {TournamentImgPipeLogic} from '@shared/pipes/tournamentImgPipeLogic';

@Pipe({
    name: 'tournamentImgPipe'
})
export class TournamentImgPipe implements PipeTransform {
    transform(tournamentType: string) {

        return TournamentImgPipeLogic(tournamentType);
    }
}

