import {IconUrl} from './IconUrl';
import {MapCourt} from './MapCourt';
import {MapTournament} from './MapTournament';
import LatLngLiteral = google.maps.LatLngLiteral;
import {CourtData} from '@services/court/map-data.service';
import {TournamentMapData} from '@model/tournament/map-tournament-data';
import {TournamentImgPipeLogic} from '@shared/pipes/tournamentImgPipeLogic';

declare var google: any;

export enum MarkerType {
  court,
  tournament
}

export class MapMarker {
  position: LatLngLiteral;

  static buildBasedOnCourtData(value: CourtData): any {
    return new MapMarker(
        MarkerType.court,
        value.id,
        MapCourt.buildBasedOnCourtData(value),
        null,
        +value.latitude,
        +value.longitude,
        generateIcon(value),
        value.miniature,
        value.multisport_available
    );
  }

  constructor(
    public type: MarkerType,
    public id: number,
    public court: MapCourt = null,
    public tournament: MapTournament = null,
    public lat: number,
    public lng: number,
    public iconUrl: IconUrl,
    public thumb: string,
    public multisport: boolean
  ) {
    this.position = {lat, lng};
  }

  static buildBasedOnTournamentMapData(value: TournamentMapData) {
    return new MapMarker(
        MarkerType.tournament,
        value.id,
        null,
        MapTournament.buildBasedOnTournamentMapData(value),
        +value.latitude,
        +value.longitude,
        generateTournamentIcon(),
        generateTournamentThumb(value),
        false
    );
  }
}

const generateIcon = (court: CourtData): IconUrl => {
  return {
    url: generateIconUrl(court),
    scaledSize: {
      height: 47,
      width: 36
    }
  };
};

const generateIconUrl = (court: CourtData): string => {
  const baseUrl = '/assets/images/svg/';
  switch (court.marker) {
    case 'ACTIVE':
      return baseUrl + 'marker_aktywne.png';
    case 'FLOODED':
      return baseUrl + 'marker_zalane.png';
    case 'NO_ACTIVE_STATIONS':
      return baseUrl + 'marker_nieaktywny.png';
    case 'RESERVATIONS_DISABLED':
      return baseUrl + 'marker_bez_rezerwacji.png';
    default:
      return baseUrl + 'marker_bez_rezerwacji.png';
  }
};

/**
 * zielony marker dla turniejów z zapisami
 * niebieski dla pozostałych
 * jeśli w jednej lokalizacji jest kilka turniejów to jeśli choć 1 turniej jest w trakcie zapisów to zielony marker
 */
export const generateTournamentIcon = (blue = true, challenge = false): IconUrl => {
  return {
    url: blue ? '/assets/images/svg/marker_niebieski.png' : '/assets/images/svg/marker_aktywne.png',
    scaledSize: {
      height: 47,
      width: 36
    }
  };
};

const generateTournamentThumb = (item: TournamentMapData): string => {
  return TournamentImgPipeLogic(item.type);
};
