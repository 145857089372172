<app-loader [visible]="!whoami || !stats"></app-loader>

<div *ngIf="whoami && stats" class="dashboard-container">
  <div class="row desktop-only">
    <div class="col-lg-4">

      <div *ngIf="whoami.next_reservation" class="dashboard-card bg-info">
        <div class="title">Najbliższa rezerwacja</div>
        <app-dashboard-next-reservation
            [reservation]="whoami.next_reservation"
        ></app-dashboard-next-reservation>
      </div>

      <div *ngIf="reservations.repeatable.length" class="dashboard-card bg-white">
        <app-card-title [title]="'Rezerwacje stałe'" (stateChanged)="loadRepReservations($event)"></app-card-title>
        <app-dashboard-reservations-rep
            [isMobile]="false"
            [history]="historyRep"
            [reservations]="reservations.repeatable"
            (onRefreshReservations)="loadData()"
        ></app-dashboard-reservations-rep>
      </div>

      <div *ngIf="reservations.single" class="dashboard-card bg-white">
        <app-card-title [title]="'Rezerwacje jednorazowe'" (stateChanged)="loadReservations($event)"></app-card-title>
        <app-dashboard-reservations-single
                [history]="historySingle"
            [reservations]="reservations.single"
            (onReservationUpdated)="loadData()"
        ></app-dashboard-reservations-single>
      </div>

    </div>
    <div class="col-lg-4">

      <div class="dashboard-card bg-white">
        <div class="title">Mój ranking</div>
        <app-dashboard-ranking
            [ranking]="stats"
        ></app-dashboard-ranking>
      </div>

      <div class="dashboard-card bg-white">
        <div class="title">Moje ulubione korty</div>
        <app-dashboard-fav-courts
            [data]="whoami.favorite_courts"
        ></app-dashboard-fav-courts>
      </div>

    </div>
    <div class="col-lg-4">

<!--      <div *ngFor="let challenge of challenges" (click)="goto(challenge.tournament_id)"-->
      <div *ngFor="let challenge of challenges"
          class="dashboard-card bg-amber-500 cursor-pointer" style="padding-bottom: 10px;">
        <div class="title default-color">Otrzymałeś wyzwanie !</div>
        <app-dashboard-challenge-pending
            [challenge]="challenge"
            (dataChanged)="refreshChallenges()"
        ></app-dashboard-challenge-pending>
      </div>

      <div *ngIf="whoami.last_match" class="dashboard-card bg-white" style="padding-bottom: 10px;">
        <div class="title">Ostatni wynik w turnieju / lidze</div>
        <app-dashboard-tournament-score
            [match]="whoami.last_match"
        ></app-dashboard-tournament-score>
      </div>

      <div class="dashboard-card bg-white">
        <app-card-title [title]="'Moje turnieje / ligi'" [historyTitle]="'Zakończone'"
                        (stateChanged)="loadTournaments($event)"></app-card-title>
        <app-dashboard-my-tournaments
            [tournaments]="userTournaments"
        ></app-dashboard-my-tournaments>
      </div>

    </div>
  </div>

  <div class="row mobile-only">
    <div class="col-lg-4">

<!--      <div *ngFor="let challenge of challenges" (click)="goto(challenge.tournament_id)"-->
      <div *ngFor="let challenge of challenges"
           class="dashboard-card bg-amber-500 cursor-pointer" style="padding-bottom: 10px;">
        <div class="title default-color">Otrzymałeś wyzwanie !</div>
        <app-dashboard-challenge-pending
            [challenge]="challenge"
            (dataChanged)="refreshChallenges()"
        ></app-dashboard-challenge-pending>
      </div>

      <div *ngIf="whoami.next_reservation" class="dashboard-card bg-info">
        <div class="title">Najbliższa rezerwacja</div>
        <app-dashboard-next-reservation
            [reservation]="whoami.next_reservation"
        ></app-dashboard-next-reservation>
      </div>

      <div *ngIf="whoami.last_match" class="dashboard-card bg-white" style="padding-bottom: 10px;">
        <div class="title">Ostatni wynik w turnieju / lidze</div>
        <app-dashboard-tournament-score
            [match]="whoami.last_match"
        ></app-dashboard-tournament-score>
      </div>

      <div class="dashboard-card bg-white">
        <div class="title">Mój ranking</div>
        <app-dashboard-ranking
            [ranking]="stats"
        ></app-dashboard-ranking>
      </div>

      <div class="dashboard-card bg-white">
        <app-card-title [title]="'Moje turnieje / ligi'" [historyTitle]="'Zakończone'"
                        (stateChanged)="loadTournaments($event)"></app-card-title>
        <app-dashboard-my-tournaments
            [tournaments]="userTournaments"
        ></app-dashboard-my-tournaments>
      </div>

      <div *ngIf="reservations.repeatable.length" class="dashboard-card bg-white">
        <app-card-title [title]="'Rezerwacje stałe'" (stateChanged)="loadRepReservations($event)"></app-card-title>
        <app-dashboard-reservations-rep
            [isMobile]="true"
            [history]="historyRep"
            [reservations]="reservations.repeatable"
            (onRefreshReservations)="loadData()"
        ></app-dashboard-reservations-rep>
      </div>

      <div class="dashboard-card bg-white">
        <app-card-title [title]="'Rezerwacje jednorazowe'" (stateChanged)="loadReservations($event)"></app-card-title>
        <app-dashboard-reservations-single
                [history]="historySingle"
                [reservations]="reservations.single"
            (onReservationUpdated)="refreshReservations()"
        ></app-dashboard-reservations-single>
      </div>

      <div class="dashboard-card bg-white">
        <div class="title">Moje ulubione korty</div>
        <app-dashboard-fav-courts
            [data]="whoami.favorite_courts"
        ></app-dashboard-fav-courts>
      </div>

    </div>

  </div>
</div>
