export const API_HOST = 'https://api.tenis4u.pl';
// export const API_HOST = 'https://test.tenis4u.pl';
//   export const API_HOST = 'http://localhost:8082';

export const APP_VERSION = '3.4.0';

// export const PAYMENT_METHOD: string ='DOTPAY'; // DOTPAY,TPAY -- aktualna produkcja
// export const PAYMENT_METHOD = 'TPAY'; // DOTPAY,TPAY -- test - produkcja od 18.02.2021
export const PAYMENT_METHOD = 'PRZELEWY24'; // PRZELEWY24 OD 01.04.24
export const WITH_VERTICAL_DAY_RESERVATION = true;
export const AD_DISPLAY_INTERVAL = 172800000; // 48h
export const WITH_NEW_FILTERS = false;
export const uniqueBuildHash = 'nnpjdGdJ0eQ+LObq'
