import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AppStateService} from '@core/state/app-state.service';
import {UserPanelService} from '@services/user/user-panel.service';
import apiMessageTranslate from '../../../../shared/utils/apiMessageTranslate';
import {swalSuccess} from '@shared/swal/swalSuccess';
import {swalError} from '@shared/swal/swalError';
import {Whoami} from '@core/authentication/whoami';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-user-panel-voucher',
    styleUrls: ['../user-panel.component.scss'],
    template: `
        <div class="card">
            <div class="card-content">
                <h5 class="card-header">Karta Tenisowa VISA</h5>
                <div *ngIf="!withVisaCardNumber" class="">
                    Masz kartę tenisową Visa Banku BNP Paribas? Skorzystaj z 20% zniżki na rezerwację kortów
                    przy płatności kartą online. Szczegóły na stronie <a
                        href="https://bnpparibas.pl/kartatenisowa"
                        target="_blank">bnpparibas.pl/kartatenisowa</a>
                </div>
                <div *ngIf="withVisaCardNumber" class="">
                    <div class="progress-container">
                        <div class="progress" style="margin-bottom: 5px;">
                            <div class="progress-bar color-green-500"
                                 role="progressbar"
                                 [style.width.%]="visaCardProgress"
                            ></div>
                        </div>
                    </div>
                    <div>Dotychczasowy wykorzystany rabat <b>{{user.active_vouchers[0].value}}/{{user.active_vouchers[0].balance}}</b> zł</div>
                    <div>Rabat aktywny do dnia <b>{{user.active_vouchers[0].active_until | momentDate}}</b></div>
                </div>

                <div *ngIf="!withVisaCardNumber && !showVisaCardNumberInput"
                     class="animated fadeIn text-center mt-15">
                    <div class="bnp-card-disabled">
                        <img src="/assets/images/bnp-disabled.png">
                        <button class="btn-raised btn btn-primary btn-oval" (click)="setVisaCardNumber()">Mam kartę</button>
                    </div>
                </div>

                <div *ngIf="showVisaCardNumberInput" class="animated fadeIn text-center mt-15">
                    <div class="alert alert-green mb-20 text-left">Aby uzyskać 20% zniżki na rezerwację kortów,
                        wprowadź kod zniżkowy pobrany ze strony <a href="https://visa.pl/pl_pl/visa-benefit"
                                                                   target="_blank">https://visa.pl/pl_pl/visa-benefit</a>
                    </div>
                    <div class="input-container">
                        <input type="text" [(ngModel)]="cardNumberModel" class="form-control"/>
                        <button class="btn btn-success" (click)="saveVisaCardNumber()">Zapisz</button>
                    </div>
                </div>

                <div *ngIf="withVisaCardNumber" class="animated fadeIn text-center mt-15">
                    <div class="bnp-card-active">
                        <img src="/assets/images/bnp-active.png">
                    </div>
                </div>
            </div>
        </div>

    `,
})
export class UserPanelVoucherComponent implements OnInit {
    @Input() user: Whoami;
    @Output() loadUserData = new EventEmitter<void>();

    showVisaCardNumberInput = false;
    withVisaCardNumber = false;
    visaCardProgress = 0;
    cardNumberModel: string;

    constructor(private service: UserPanelService,
                private toastr: ToastrService,
                private state: AppStateService) {
    }

    ngOnInit() {
        this.manageVisaCardStatus();
    }

    setVisaCardNumber() {
        this.showVisaCardNumberInput = true;
    }

    saveVisaCardNumber() {
        this.service.setVisaCardNumber(this.cardNumberModel).subscribe(
            () => {
                this.loadUserData.emit();
                this.showVisaCardNumberInput = false;
                this.toastr.success('Numer karty zapisany');
                this.withVisaCardNumber = true;
                this.showVisaCardNumberInput = false;
            }, err => this.toastr.error(err.error.message, 'Błąd podczas zapisywania numeru karty')
        );
    }

    private manageVisaCardStatus() {
        const withActiveVisa = this.user.active_vouchers.length > 0;
        if (!withActiveVisa) {
            return;
        }

        const voucher = this.user.active_vouchers[0];
        this.withVisaCardNumber = true;
        this.visaCardProgress = voucher.value / voucher.balance * 100;
    }
}
