import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {MyTournament} from '@model/tournament/my-tournament';

@Component({
  selector: 'app-dashboard-my-tournaments',
  template: `
    <div class="no-data-title" *ngIf="!tournaments.length">
      <div class="header">Nie bierzesz obecnie udziału w żadnych rozgrywkach.</div>
      Kliknij na mapę turniejów i rozpocznij rywalizację.
    </div>
    <div *ngIf="tournaments.length" class="my-tournaments">
      <div *ngFor="let tournament of tournaments; index as i"
           class="card-body cursor-pointer" (click)="goto(tournament)"
           [class.border-bottom-light]="i !== 0"
      >
        <div class="row animated fadeIn" style="display: flex;">
          <div class="col-xs-4" style="display: flex; align-items: center; justify-content: center;">
            <img loading="lazy" class="rta-img" src="{{tournament.type | tournamentImgPipe}}">
<!--            <img loading="lazy" class="rta-img" *ngIf="tournament.type !== 'CHALLENGE'" src="{{tournament.type | rtaImgPipe}}">-->
<!--            <img loading="lazy" style="max-width: 80%;height: auto;margin: 10px auto;" *ngIf="tournament.type === 'CHALLENGE'" src="/assets/images/tournament2/challenge.png">-->
          </div>
          <div class="col-xs-8">
            <div class="card-content">
              <div class="name">{{tournament.name}}</div>
              <div class="text-muted">typ turnieju:
                <app-tournament-type [type]="tournament.type"></app-tournament-type>
              </div>
              <div *ngIf="tournament.players" class="text-muted">liczba graczy: {{tournament.players}}/{{tournament.max_players}}
                <span *ngIf="tournament.reserve_players"> (rez.: {{tournament.reserve_players}})</span>
              </div>
              <div class="text-muted">data rozpoczęcia: {{tournament.start_date | momentDate:'DD-MM-YYYY'}}</div>
              <div class="text-muted" *ngIf="tournament.position">pozycja: {{tournament.position | tournamentPositionPipe}}</div>
              <app-tournament-status *ngIf="tournament.status !== 'FINISHED'" [status]="tournament.status"></app-tournament-status>
              <span *ngIf="tournament.ends_date && tournament.status === 'FINISHED'" class="badge badge-boxed bg-grey-300 color-black">zakończony: {{tournament.ends_date}}</span>
              <span *ngIf="tournament.reserve_list" class="badge badge-boxed bg-blue-500">rezerwowy</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./../dashboard.component.scss']
})
export class DashboardMyTournamentsComponent {
  private _tournaments: MyTournament[] = [];

  get tournaments() {
    return this._tournaments;
  }

  @Input('tournaments')
  set tournaments(value) {
    this._tournaments = value;
    if (value) {
      this._tournaments = value.filter(t => !(t.reserve_list && !(t.status === 'CREATED' || t.status === 'APPLICATIONS_CLOSED')))
      if (value.length > 0 && value[0].status === 'FINISHED') {
        this._tournaments = this._tournaments.sort((t1, t2) => new Date(t1.ends_date) >= new Date(t2.ends_date) ? -1 : 1)
      } else {
        this._tournaments = this._tournaments.sort((t1, t2) => new Date(t1.start_date) >= new Date(t2.start_date) ? -1 : 1)
      }
    }
  }

  constructor(private router: Router) {
  }

  goto(t: MyTournament) {
    this.router.navigate(['tournament/' + t.id], {queryParams: {'tab': 'info'}});
  }

}
