<div class="container-full">
    <div class="page-container bg-blue-grey-900">
        <div class="container container-xs">
            <img loading="lazy" width="150px" src="/assets/images/tenis4U-white-logo-S.png" class="mv-lg block-center img-responsive"/>

            <div *ngIf="userCreated" class="card bg-success">
                <div class="card-heading"><em class="ion-checkmark-round pull-right icon-lg"></em>
                    <h4 class="mt0">Numer telefonu potwierdzony</h4>
                </div>
                <div class="card-body">
                    <p>Możesz się zalogować</p>
                </div>
                <div class="card-footer text-right">
                    <a [routerLink]="'/login'" class="btn btn-success btn-flat text-white">Logowanie <em class="ion-ios-arrow-thin-right icon-fw"></em></a>
                </div>
            </div>

            <form *ngIf="!userCreated && modal" [formGroup]="smsForm" class="card form-validate" (submit)="submitForm($event, smsForm)" novalidate>
                <div class="card-heading">
                    <p class="text-center text-inherit">Podaj kod SMS</p>
                </div>
                <div class="card-body">
                    <div class="mda-form-group float-label mda-input-group">
                        <div class="mda-form-control">
                            <input type="text" name="accountName" formControlName="number" [formControl]="smsForm.controls['number']" class="form-control" />
                            <div class="mda-form-control-line"></div>
                            <label>Kod SMS</label>
                        </div>
                        <span class="text-danger" *ngIf="smsForm.controls['number'].hasError('required') && (smsForm.controls['number'].dirty || smsForm.controls['number'].touched)">Pole wymagane</span>
                        <span class="mda-input-group-addon"><em class="ion-ios-calculator-outline icon-lg"></em></span>
                    </div>
                    <div class="text-center">
                        <button [disabled]="!smsForm.valid" type="submit" class="btn btn-success btn-sm">Załóż konto <em class="ion-arrow-right-c"></em></button>
                    </div>
                </div>
            </form>
            <form *ngIf="!userCreated && !modal" [formGroup]="regForm" novalidate class="card b0 form-validate" (submit)="submitForm($event, regForm)" novalidate="">
                <div class="card-offset pb0">
                    <div *ngIf="regForm.valid" class="card-offset-item text-right">
                        <button type="submit" class="btn btn-success btn-circle btn-lg"><em class="ion-checkmark-round"></em></button>
                    </div>
                </div>
                <div class="card-heading">
                    <div class="card-title text-center">Tworzenie konta</div>
                </div>
                <div class="card-body">
                    <div class="mda-form-group float-label mda-input-group">
                        <div class="mda-form-control">
                            <input type="text" name="accountName" formControlName="name" [formControl]="regForm.controls['name']" class="form-control" />
                            <div class="mda-form-control-line"></div>
                            <label>Imię i nazwisko</label>
                        </div>
                        <span class="text-danger" *ngIf="regForm.controls['name'].hasError('required') && (regForm.controls['name'].dirty || regForm.controls['name'].touched)">Pole wymagane</span>
                        <span class="mda-input-group-addon"><em class="ion-ios-person-outline icon-lg"></em></span>
                    </div>
                    <div class="mda-form-group float-label mda-input-group">
                        <div class="mda-form-control">
                            <input type="text" name="accountName" formControlName="phone" [formControl]="regForm.controls['phone']" class="form-control" />
                            <div class="mda-form-control-line"></div>
                            <label>Numer telefonu</label>
                        </div>
                        <span class="text-danger" *ngIf="regForm.controls['phone'].hasError('required') && (regForm.controls['phone'].dirty || regForm.controls['phone'].touched)">Pole wymagane</span>
                        <span class="text-danger" *ngIf="regForm.controls['phone'].hasError('minlength') && (regForm.controls['phone'].dirty || regForm.controls['phone'].touched)">Numer telefonu musi składać się 9 znaków</span>
                        <span class="text-danger" *ngIf="regForm.controls['phone'].hasError('maxlength') && (regForm.controls['phone'].dirty || regForm.controls['phone'].touched)">Numer telefonu musi składać się 9 znaków</span>
                        <span class="mda-input-group-addon"><em class="ion-ios-telephone-outline icon-lg"></em></span>
                    </div>
                    <div class="mda-form-group float-label mda-input-group">
                        <div class="mda-form-control">
                            <input type="email" name="accountName" formControlName="email" [formControl]="regForm.controls['email']" class="form-control" />
                            <div class="mda-form-control-line"></div>
                            <label>Adres e-mail</label>
                        </div>
                        <span class="text-danger" *ngIf="regForm.controls['email'].hasError('required') && (regForm.controls['email'].dirty || regForm.controls['email'].touched)">Pole wymagane</span>
                        <span class="text-danger" *ngIf="regForm.controls['email'].hasError('email') && (regForm.controls['email'].dirty || regForm.controls['email'].touched)">Niepoprawny format adresu e-mail</span>
                        <span class="mda-input-group-addon"><em class="ion-ios-email-outline icon-lg"></em></span>
                    </div>
                    <div class="mda-form-group float-label mda-input-group">
                        <div class="mda-form-control">
                            <input type="password" name="accountPassword" formControlName="password" [formControl]="regForm.controls['password']" class="form-control" />
                            <div class="mda-form-control-line"></div>
                            <label>Hasło</label>
                        </div>
                        <span class="text-danger" *ngIf="regForm.controls['password'].hasError('required') && (regForm.controls['password'].dirty || regForm.controls['password'].touched)">Pole wymagane</span>
                        <span class="mda-input-group-addon"><em class="ion-ios-locked-outline icon-lg"></em></span>
                    </div>
                    <div class="mda-form-group float-label mda-input-group">
                        <div class="mda-form-control">
                            <input type="password" name="accountPassword2" formControlName="confirmPassword" [formControl]="regForm.controls['confirmPassword']" class="form-control" />
                            <div class="mda-form-control-line"></div>
                            <label>Powtórz hasło</label>
                        </div>
                        <span class="text-danger" *ngIf="regForm.controls['confirmPassword'].errors?.equalTo">Podane hasła nie są identyczne</span>
                        <span class="mda-input-group-addon"><em class="ion-ios-locked-outline icon-lg"></em></span>
                    </div>

                    <div>
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" id="termsInput" (click)="termsInputChange($event)"/>
                                <span class="text-inherit">Akceptuję <a href="https://tenis4u.pl/regulamin/" target="_blank">regulamin</a>
                                    <span class="text-danger">*</span>
                                </span>
                            </label>
                        </div>
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" id="privacyInput" (click)="privacyInputChange($event)"/>
                                <span class="text-inherit">Akceptuję <a href="https://tenis4u.pl/privacypolicy/" target="_blank">politykę prywatności</a> <span class="text-danger">*</span></span>
                            </label>
                        </div>
                    </div>
                </div>
                <button type="submit" [disabled]="!regForm.valid || regForm.pristine || !termsModel || !privacyModel" class="btn btn-primary btn-flat">Zarejestruj</button>
            </form>
            <div class="text-center text-sm"><a [routerLink]="'/login'" class="text-inherit">Logowanie</a></div>
        </div>
    </div>
</div>
