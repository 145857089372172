<div class="court-info card animated fadeIn" *ngIf="courtData"
     [class.border-none]="withoutBorder"
>
    <div class="card-heading">
        <div class="card-title text-left">
            {{courtData.name}}
            <app-heart [rightPadding]="withoutLogoSection ? 60 : 15" [courtId]="courtData.id"></app-heart>
        </div>
    </div>

    <div *ngIf="!withoutLogoSection">
        <p style="margin-bottom: 5px;">
            <img loading="lazy" *ngIf="courtData.logo" alt="logo" src="{{courtData.logo}}">
            <img loading="lazy" *ngIf="!courtData.logo" alt="logo" src="/assets/images/logo_default.png">
        </p>
        <div class="b-b-3 space-around" style="display: flex; padding: 10px 0;">
            <a href="https://www.google.com/maps?ll={{courtData.latitude}},{{courtData.longitude}}" target="_blank"
               style="color: rgb(108, 113, 119)">
                <button class="btn btn-flat">
                    <em class="ion-android-compass"></em>
                    <span class="text"> NAWIGUJ</span>
                </button>
            </a>
            <a *ngIf="courtData.contact_number" href="tel:{{courtData.contact_number}}" class="" style="color: rgb(108, 113, 119)">
                <button class="btn btn-flat btn-secondary">
                    <em class="ion-android-call"></em>
                    <span class="text"> {{ courtData.contact_number }}</span>
                </button>
            </a>
            <!--                <a *ngIf="!courtData.contact_number" class=" btn disabled">-->
            <!--                    <button class="btn btn-outline-blue-grey-400">-->
            <!--                        <em class="ion-android-phone-portrait"></em>-->
            <!--                        <span class="text">ZADZWOŃ</span>-->
            <!--                    </button>-->
            <!--                </a>-->
            <a href="{{courtData.website}}" target="_blank" style="color: rgb(108, 113, 119)">
                <button class="btn btn-flat">
                    <em class="ion-android-globe"></em>
                    <span class="text"> WWW</span>
                </button>
            </a>
        </div>
    </div>
    <div class="opening-hours b-b-3">
        <div class="row">
            <div class="col-xs-6">
                <p>czynne w tygodniu</p>
                <h5>{{courtData.opening}} - {{courtData.closing}}</h5>
            </div>
            <div class="col-xs-6">
                <p>czynne weekend</p>
                <h5>{{courtData.opening_weekend}} - {{courtData.closing_weekend}}</h5>
            </div>
        </div>
    </div>

    <div class="box b-b-3" style="padding-top: 16px;">
        <div *ngFor="let type of stationTypes(); index as i">
            <p class="type">{{type}}</p>
            <app-ball class="ball" *ngFor="let color of stationBalls(type)" [color]="color"></app-ball>
        </div>
    </div>

    <div class="box">
        <div class="card row hoverable" (click)="openCourtTournamentsModal()">
            <h4 class="m0 text-thin col-xs-2" style="padding: 2px;"><i class="fa fa-trophy"></i></h4>
            <span class="content col-xs-10">Turnieje i ligi rozgrywane w klubie <em
                    style="float: right; line-height: 1;" class="ion-ios-arrow-right"></em></span>
        </div>
        <div *ngIf="courtData.reservation_enabled">

            <div *ngIf="courtData.one_reservation_policy" class="card row">
                <span class="content col-xs-12">zgodnie z regulaminem kortu 1 osoba może zarezerwować 1 godzinę dziennie</span>
            </div>

            <div class="card row">
                <h4 class="m0 text-thin col-xs-2">$</h4>
                <span class="content col-xs-10" *ngIf="courtData.payment === 'CASH'">Płatność tylko w klubie</span>
                <span class="content col-xs-10" *ngIf="courtData.payment === 'CASH_AND_ONLINE'">Płatność w klubie lub online</span>
                <span class="content col-xs-10" *ngIf="courtData.payment === 'ONLINE'">Płatność tylko online</span>
            </div>

            <div *ngIf="courtData.multisport_available" class="card row">
                <h4 class="m0 text-thin col-xs-2"><img loading="lazy" style="margin: 5px auto;" width="25"
                                                       src="assets/img/icons/checkmark-circle-outline.svg"/></h4>
                <span class="content col-xs-10">Obsługa kart multisport</span>
            </div>
            <div *ngIf="courtData.medicover_available" class="card row">
                <h4 class="m0 text-thin col-xs-2"><img loading="lazy" style="margin: 5px auto;" width="25"
                                                       src="assets/img/icons/checkmark-circle-outline.svg"/></h4>
                <span class="content col-xs-10">Obsługa kart medicover</span>
            </div>

            <div *ngIf="courtData.hours_cancel_limit > 0" class="card row">
                <h4 class="m0 text-thin col-xs-2">{{courtData.hours_cancel_limit}}</h4>
                <span class="content col-xs-10">godzin przed rozpoczęciem można odwołać rezerwację jednorazową</span>
            </div>
            <div *ngIf="courtData.hours_repeatable_cancel_limit > 0" class="card row">
                <h4 class="m0 text-thin col-xs-2">{{courtData.hours_repeatable_cancel_limit}}</h4>
                <span class="content col-xs-10">godzin przed rozpoczęciem można odwołać rezerwację stałą</span>
            </div>
            <div class="card row">
                <h4 class="m0 text-thin col-xs-2">0</h4>
                <span class="content col-xs-10">godzin przed rozpoczęciem można odwołać rezerwację jeśli korty są zalane - oznaczone są żółtym kolorem</span>
            </div>
            <div *ngIf="courtData.hours_cancel_limit == 0" class="card row">
                <h4 class="m0 text-thin col-xs-2">&infin;</h4>
                <span class="content col-xs-10">możliwość odwołania rezerwacji jednorazowej w dowolnym momencie</span>
            </div>
            <div *ngIf="courtData.hours_repeatable_cancel_limit == 0" class="card row">
                <h4 class="m0 text-thin col-xs-2">&infin;</h4>
                <span class="content col-xs-10">możliwość odwołania rezerwacji stałej w dowolnym momencie</span>
            </div>

            <div *ngIf="courtData.active_reservations_limit_per_user > 0" class="card row">
                <h4 class="m0 text-thin col-xs-2">{{courtData.active_reservations_limit_per_user}}</h4>
                <span class="content col-xs-10">możliwych aktywnych rezerwacji</span>
            </div>
            <div *ngIf="courtData.active_reservations_limit_per_user === 0" class="card row">
                <h4 class="m0 text-thin col-xs-2"><em class="ion-ios-infinite"></em></h4>
                <span class="content col-xs-10">możliwość posiadania dowolnej ilości rezerwacji</span>
            </div>

            <div *ngIf="courtData.reservation_days_ahead > 0" class="card row">
                <h4 class="m0 text-thin col-xs-2">{{courtData.reservation_days_ahead}}</h4>
                <span class="content col-xs-10">dni w przód możliwych do rezerwacji</span>
            </div>
            <div *ngIf="courtData.reservation_days_ahead === 0" class="card row">
                <h4 class="m0 text-thin col-xs-2">&infin;</h4>
                <span class="content col-xs-10">dowolna ilość dni możliwa do rezerwacji w przód</span>
            </div>
        </div>

        <div *ngIf="!courtData.reservation_enabled" class="card row">
            <span class="content col-xs-12">rezerwacja off-line</span>
        </div>
        <div *ngIf="!courtData.reservation_enabled">
            <div *ngIf="courtData.multisport_available" class="card row">
                <h4 class="m0 text-thin col-xs-2"><img loading="lazy" style="margin: 5px auto;" width="25"
                                                       src="assets/img/icons/checkmark-circle-outline.svg"/></h4>
                <span class="content col-xs-10">Obsługa kart multisport</span>
            </div>
            <div *ngIf="courtData.medicover_available" class="card row">
                <h4 class="m0 text-thin col-xs-2"><img loading="lazy" style="margin: 5px auto;" width="25"
                                                       src="assets/img/icons/checkmark-circle-outline.svg"/></h4>
                <span class="content col-xs-10">Obsługa kart medicover</span>
            </div>
        </div>
    </div>
</div>

<div class="card animated fadeIn mt-8" *ngIf="courtData?.template" [class.border-none]="withoutBorder">
    <div class="card-heading" style="border-bottom: 1px solid #f5f5f5;">
        <div class="text-center">Informacje dodatkowe</div>
    </div>
    <div class="box">
        <p class="description">{{courtData.template}}</p>
    </div>
</div>

<div class="card animated fadeIn mt-8" *ngIf="courtData?.rules" [class.border-none]="withoutBorder">
    <div class="card-heading" style="border-bottom: 1px solid #f5f5f5;">
        <div class="text-center">Regulamin</div>
    </div>
    <div class="box">
        <p class="description">{{courtData.rules}}</p>
    </div>
</div>
