import {filter, switchMap, tap} from 'rxjs/operators';
import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import {MenuService, SidebarMenuLink} from '@core/menu/menu.service';
import {AppStateService} from '@core/state/app-state.service';
import {Warn} from '@model/user/Warn';
import {SettingsService} from '@shared/settings/settings.service';
import {AuthenticationService} from '@core/authentication/authentication.service';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {API_HOST} from '../../consts';
import {CourtDetailService} from '@services/court-detail.service';
import {CourtDetail} from '@model/court/CourtDetail';
import {StationPricing} from '@model/court/pricing/StationPricing';
import {getWhoami} from '@shared/utils/whoami';
import {TournamentService} from '@services/tournament/tournament.service';

declare var $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {
    public courtData: CourtDetail;
    public images: string[] = null;
    public pricing: StationPricing[];


    menu: SidebarMenuLink[];
    @ViewChild('settingsModal', {static: true}) public settingsModal: ModalDirective;
    asideTitle = '';
    activeAside = 'fav';

    public courtId: number;
    isCourtRoute = false;
    logoPFT = false;
    PFT: boolean;
    tournamentId: number;

    constructor(private menuService: MenuService,
                public settings: SettingsService,
                public state: AppStateService,
                private router: Router,
                private courtDetailService: CourtDetailService,
                private tournamentService: TournamentService,
                private authService: AuthenticationService) {

        this.menu = menuService.getMenuSorted();

        this.state.onOpenCourtTournaments().subscribe((courtId: number) => {
            this.courtId = courtId;
            this.asideTitle = 'Lista turniejów kortu';
            this.activeAside = 'court-tournaments';
            this.settingsModal.show();
        });
        this.state.onCloseReservationModal().subscribe(() => this.settingsModal.hide());
    }

    ngOnInit() {
        this.extendMenu();
        this.checkIfPFTtournamentRoute();
        if (this.checkIfCourtRoute()) {
            this.courtId = +this.router.url.slice(7);
            this.menu = this.menuService.getMenuForCourtView();
            this.loadCourtDataById(this.courtId);
        }
        this.extendMenu();
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd))
            .subscribe((event: RouterEvent) => {
                if (this.checkIfCourtRoute()) {
                    this.courtId = +event.url.slice(7);
                    this.menu = this.menuService.getMenuForCourtView();
                    this.loadCourtDataById(this.courtId);
                } else {
                    this.menu = this.menuService.getMenuSorted();
                }
                this.settings.app.sidebar.visible = false;
                this.checkIfPFTtournamentRoute();
                this.extendMenu();
            });
    }

    loadCourtDataById(id: number) {
        if (!id) {
            return;
        }
        this.courtDetailService.getCourtBasicData(id).pipe(
            tap(data => this.loadDetails(data)),
            switchMap(data => this.courtDetailService.tariffByCourt(data.id)),
            tap((pricing: StationPricing[]) => this.pricing = pricing),
        ).subscribe();
    }

    loadDetails(data: any) {
        this.courtData = data;
        this.images = data.images.map((img: string) => `${API_HOST}/courtGallery/show/${img}`);
    }

    hideSidebar() {
        this.asideTitle = null;
        this.settingsModal.hide();
    }

    extendMenu() {
        const user = getWhoami();
        if (!user || user.warns.length === 0) {
            this.menu = this.menu.filter(item => item.link !== '/user/warnings');
            return;
        }
        const warns: Warn[] = user.warns;
        this.menu[0].label.count = warns.length;
        this.menu[0].label.classname = warns.length > 0 ? 'badge bg-danger' : 'badge bg-success';
    }

    gotoLogin() {
        localStorage.setItem('redirect', '/user/dashboard');
        this.router.navigate(['login']);
    }

    gotoUserPanel() {
        this.router.navigate(['user/panel']);
    }

    closeSidebar() {
        this.settings.app.sidebar.visible = false;
    }

    sidebarAction(link: string) {
        if (link === '/court/map/start' || link === '/court/map/tournaments') {
            this.state.setMapViewDefault();
            this.router.navigate([link]);
            return;
        }
        switch (link) {
            case '/court-detail/info':
                this.asideTitle = 'Informacje o korcie';
                this.activeAside = 'info';
                this.settingsModal.show();
                return;
            case '/court-detail/gallery':
                this.asideTitle = 'Galeria';
                this.activeAside = 'gallery';
                this.settingsModal.show();
                return;
            case '/court-detail/pricing':
                this.asideTitle = 'Cennik';
                this.activeAside = 'pricing';
                this.settingsModal.show();
                return;
            case '/court-detail/tournaments':
                this.asideTitle = 'Turnieje';
                this.activeAside = 'tournaments';
                this.settingsModal.show();
                return;
        }
        if (!this.state.userExists()) {
            this.authService.toastrMsg('Dostęp tylko dla zalogowanych użytkowników', '', true);
            return;
        }
        switch (link) {
            case '/user/warnings':
                this.asideTitle = 'Ostrzeżenia użytkownika';
                this.activeAside = 'warn';
                this.settingsModal.show();
                break;
        }
        if (link === '/user/dashboard') {
            this.router.navigate(['user/dashboard']);
            return;
        }
    }

    private checkIfCourtRoute(): boolean {
        this.isCourtRoute = this.router.url.includes('/court/') && !this.router.url.includes('map');
        return this.isCourtRoute;
    }

    private checkIfPFTtournamentRoute(): void {
        if (this.router.url.includes('/tournament/pft-ranking/')
            || this.router.url.includes('/tournament/pft-timetable')) {
            this.logoPFT = true;
            this.PFT = true;
            this.settings.app.sidebar.visible = false;
            this.settings.app.sidebar.offcanvas = true;
            $('body').addClass('force-toggle-sidebar');
        }
        const isTournamentRoute = this.router.url.includes('/tournament/');
        if (!isTournamentRoute) {
            this.logoPFT = false;
            this.PFT = false;
            this.tournamentId = null;
            $('body').removeClass('force-toggle-sidebar');
            this.settings.app.sidebar.visible = false;
            this.settings.app.sidebar.offcanvas = false;
        }

        const regex = /\/(\d+)\?/;
        const text = this.router.url;
        const match = text.match(regex);
        const tournamentId = match ? match[1] : null;

        if (tournamentId) {
            this.tournamentId = +tournamentId;
            this.tournamentService.getTournamentDetails(+tournamentId).subscribe(next => {
                this.logoPFT = next.series_id !== null;
                this.PFT = next.series_id !== null;
                if (this.PFT) {
                    this.settings.app.sidebar.visible = false;
                    this.settings.app.sidebar.offcanvas = true;
                    $('body').addClass('force-toggle-sidebar');
                } else {
                    $('body').removeClass('force-toggle-sidebar');
                    this.settings.app.sidebar.visible = true;
                    this.settings.app.sidebar.offcanvas = false;
                }
            });
        }
    }

    manageTournament(link: string, ranking?: number) {
        console.log('link', link);
        switch (link) {
            case 'info':
                this.router.navigate([`/tournament/${this.tournamentId}`], {queryParams: {'tab': link}});
                break;
            case 'schedule':
                this.router.navigate([`/tournament/${this.tournamentId}`], {queryParams: {'tab': link}});
                break;
            case 'league-scores':
                this.router.navigate([`/tournament/${this.tournamentId}`], {queryParams: {'tab': link}});
                break;
            case 'brackets':
                this.router.navigate([`/tournament/${this.tournamentId}`], {queryParams: {'tab': link}});
                break;
            case 'ranking':
                if (ranking) {
                    this.router.navigate([`/tournament/pft-ranking/${ranking}`]);
                }
                break;
            case 'calendar':
                this.router.navigate([`/tournament/pft-timetable`]);
                break;
        }
    }
}
