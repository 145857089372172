<div class="sidebar-container">
    <div *ngIf="settings.app.sidebar.showheader" class="sidebar-header">
        <div (click)="closeSidebar()" class="pull-right pt-lg text-muted visible-xs-visible-sm hidden">
            <em class="ion-close-round"></em>
        </div>
        <a class="sidebar-header-logo text-center" href="https://tenis4u.pl" target="_blank">
            <img *ngIf="!logoPFT" src="/assets/images/tenis4U-white-logo-S.png"/>
            <img *ngIf="logoPFT" src="/assets/images/PFT-sidebar.png"
                 style="vertical-align: middle!important; margin-left: 0; width: 60%;"/>
        </a>
    </div>
    <div class="sidebar-content">
        <div *ngIf="settings.app.sidebar.showtoolbar && state.userExists()" class="sidebar-toolbar text-center">
            <a href="/#/user/panel" (click)="gotoUserPanel()">
                <div class="thumb64" style="margin: auto;">
                    <app-avatar [userId]="state.user().id" src={{state.user().picture}}></app-avatar>
                </div>
            </a>
            <div class="mt">{{state.user().real_name}}</div>
        </div>
        <div *ngIf="!state.userExists()" class="login-button">
            <a (click)="gotoLogin()" class="color-green-500">
                <div class="thumb64" style="margin: auto;">
                    <em class="ion-log-in icon-3x"></em>
                </div>
                Zaloguj / Zarejestruj
            </a>
        </div>
        <nav class="sidebar-nav">
            <ul *ngIf="!PFT">
                <li *ngFor="let item of menu">
                    <!-- support for sidebar application links-->
                    <a (click)="sidebarAction(item.link)" *ngIf="item.sidebar" target="_blank" class="ripple"
                       [class.border-top-based-on-theme]="isCourtRoute && item.name === 'Dashboard'"
                    >
                        <span *ngIf="item.label" class="pull-right nav-label">
                            <span [innerHTML]="item.label.count" [ngClass]="item.label?.classname"></span>
                        </span>
                        <span class="nav-icon" *ngIf="item.iconclass">
                            <em [ngClass]="item.iconclass"></em>
                        </span>
                        <span>{{item.name}}</span>
                    </a>
                    <!-- support for direct links-->
                    <a [href]="item.href" *ngIf="!item.sidebar && item.href" target="_blank"
                       class="ripple border-top-based-on-theme"
                       style="margin-top: 5px;">
                        <span *ngIf="item.label" class="pull-right nav-label">
                            <span [innerHTML]="item.label.count" [ngClass]="item.label?.classname"></span>
                        </span>
                        <span class="nav-icon" *ngIf="item.iconclass">
                            <em [ngClass]="item.iconclass"></em>
                        </span>
                        <span>{{item.name}}</span>
                    </a>
                </li>
            </ul>
            <ul *ngIf="PFT">
                <li><a (click)="manageTournament('ranking')" target="_blank" class="ripple">
                    <span class="nav-icon"><em class="ion-podium"></em></span>
                    <span>Ranking</span></a>
                </li>
                <li *ngFor="let seriesId of [1,2,3,4,5,6,7,8,9,10]">
                    <a (click)="manageTournament('ranking', seriesId)" target="_blank" class="ripple"
                       style="line-height: 10px;height: 30px;">
                    <span class="nav-icon"></span><span>- {{seriesId | seriesIdNamePipe }}</span></a>
                </li>

                <li><a (click)="manageTournament('calendar')" target="_blank" class="ripple"
                    style="margin-top: 15px;">
                    <span class="nav-icon"><em class="ion-android-calendar"></em></span>
                    <span>Terminarz turniejów</span></a>
                </li>
            </ul>
        </nav>
    </div>
</div>

<div bsModal #settingsModal="bs-modal" class="modal fade modal-right">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body" style="padding: 0;">
                <div (click)="hideSidebar()" class="pull-right close"
                     style="padding: 15px;position: absolute;z-index: 100;right: 10px;"><em
                        class="ion-close-round"></em></div>
                <app-favorite
                        (onCourtClick)="hideSidebar()"
                        *ngIf="activeAside === 'fav'"></app-favorite>
                <app-tournaments (onTournamentClick)="hideSidebar()" *ngIf="activeAside === 'tou'"></app-tournaments>
                <app-court-tournaments-modal [courtId]="courtId" (onTournamentClick)="hideSidebar()"
                                             *ngIf="activeAside === 'court-tournaments'"></app-court-tournaments-modal>
                <app-warnings *ngIf="activeAside === 'warn'"></app-warnings>
                <div *ngIf="activeAside == 'info' || activeAside == 'pricing' || activeAside == 'tournaments' || activeAside == 'gallery'">
                    <app-detail *ngIf="activeAside === 'info'"
                                [withoutBorder]="false"
                                [withoutLogoSection]="true"
                                [courtData]="courtData"
                    ></app-detail>
                    <app-gallery *ngIf="activeAside === 'gallery'" [images]="images"></app-gallery>
                    <app-pricing *ngIf="activeAside === 'pricing'" [pricing]="pricing"></app-pricing>
                    <app-court-tournaments-modal [courtId]="courtId" (onTournamentClick)="hideSidebar()"
                                                 *ngIf="activeAside === 'tournaments'"></app-court-tournaments-modal>
                </div>
            </div>
        </div>
    </div>
</div>
