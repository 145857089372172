import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {generateTournamentIcon, MapMarker} from '@model/map/MapMarker';
import {TournamentMapData} from '@model/tournament/map-tournament-data';
import {CourtDetailService} from '../court-detail.service';
import {API_HOST} from '../../consts';

import * as moment from 'moment';

export class GetCourtDataParams {
    constructor(
        public readonly datetime: string,
    ) {
    }
}

export interface HourMarkers {
    hour: string;
    markers: MapMarker[];
}

export class MapMarkerCachedResponse {
    constructor(
        public readonly hoursWithMarkers: HourMarkers[],
        public readonly date: string,
    ) {
    }
}

@Injectable()
export class MapDataService {

    private cachedMarkerResponses: MapMarkerCachedResponse[] = [];

    constructor(private http: HttpClient,
                private service: CourtDetailService) {
    }

    public getCourtDataWithCache(query: GetCourtDataParams = null): Observable<MapMarker[]> {

        if (!query) {
            return this.getCourtData(query);
        }

        // response with cache
        const cachedResponse = this.cachedMarkerResponses.find(value => moment(value.date).format('YYYY-MM-DD') === moment(query.datetime).format('YYYY-MM-DD'));
        if (cachedResponse) {
            return new Observable<MapMarker[]>(subscriber => {
                const requestedTime = moment(query.datetime).format('HH:mm');
                const markersOnTime = cachedResponse.hoursWithMarkers.find(value => value.hour === requestedTime).markers;
                subscriber.next(markersOnTime);
                subscriber.complete();
            });
        } else {
            return this.getCourtDataForDay(moment(query.datetime).format('YYYY-MM-DD')).pipe(
                map((response: FullDayCourtData) => {
                    const hoursWithMarkers: HourMarkers[] = [];
                    Object.keys(response).forEach((key) => {
                        const hour = moment(key).format('HH:mm');
                        const hourMarkers: MapMarker[] = response[key].map(v => MapMarker.buildBasedOnCourtData(v));

                        hoursWithMarkers.push({hour, markers: hourMarkers});
                    });
                    this.cachedMarkerResponses.push(new MapMarkerCachedResponse(hoursWithMarkers, moment(query.datetime).format('YYYY-MM-DD')));
                    return hoursWithMarkers.find(value => value.hour === moment(query.datetime).format('HH:mm')).markers;
                })
            );
        }
    }

    public getCourtData(query: GetCourtDataParams = null): Observable<MapMarker[]> {
        let params = new HttpParams();
        if (query) {
            params = params.append('dateTime', query.datetime);
        }
        return this.http.get<CourtData[]>(`${API_HOST}/court/all`, {params: params}).pipe(
            map((response: CourtData[]): MapMarker[] => {
                return response.map((value: CourtData) => MapMarker.buildBasedOnCourtData(value));
            }),
        );
    }

    public getCourtDataForDay(day: string): Observable<FullDayCourtData> {
        const params = new HttpParams().set('date', day);
        return this.http.get<FullDayCourtData>(`${API_HOST}/court/full-day`, {params});
    }

    public getTournamentData(): Observable<MapMarker[]> {
        return this.http.get<TournamentMapData[]>(`${API_HOST}/tournament/map`).pipe(
            map((response: TournamentMapData[]): MapMarker[] => {
                return response
                    .map((value: TournamentMapData) =>
                        MapMarker.buildBasedOnTournamentMapData(value))
                    .sort((a: MapMarker, b: MapMarker) => moment(a.tournament.start_date) >= moment(b.tournament.start_date) ? -1 : 1);
            }),
            map(markers => {
                return this.mapTournamentMarkers(markers);
            })
        );
    }

    private mapTournamentMarkers(markers: MapMarker[]): MapMarker[] {
        return markers.map(marker => {
            if (marker.tournament.status === 'CREATED' || this.courtHasAnyCratedTournament(markers, marker)) {
                marker.iconUrl = generateTournamentIcon(false);
            }
            if (marker.tournament.type === 'CHALLENGE' || this.courtHasAnyChallengeTournament(markers, marker)) {
                marker.iconUrl = generateTournamentIcon(false, true);
            }
            return marker;
        });
    }

    private courtHasAnyCratedTournament(markers: MapMarker[], marker: MapMarker): boolean {
        return markers
            .filter(m => m.lat === marker.lat && m.lng === marker.lng)
            .map(m => m.tournament.status)
            .filter(s => s === 'CREATED')
            .length > 0;
    }

    private courtHasAnyChallengeTournament(markers: MapMarker[], marker: MapMarker): boolean {
        return markers
            .filter(m => m.lat === marker.lat && m.lng === marker.lng)
            .map(m => m.tournament.type)
            .filter(t => t === 'CHALLENGE')
            .length > 0;
    }

}

export interface CourtData {
    id: number;
    lat: string;
    latitude: string;
    lng: string;
    longitude: string;
    marker: string;
    name: string;
    reservationAvailable: boolean;
    courtStatus: string; // FLOODED, OPEN, CLOSED
    img: string;
    min_cost: string;
    max_cost: string;
    miniature: string;
    multisport_available: boolean;
    medicover_available: boolean;
    amount_for_fixed_hour: number;
    sports_type: string[];
}

export interface FullDayCourtData {
    response: { [key: string]: CourtData[] };
}
