import {Injectable} from '@angular/core';
import {Observable, of as observableOf, throwError as observableThrowError} from 'rxjs';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {catchError, map, switchMap} from 'rxjs/operators';
import {Token} from '@model/auth/token';
import {API_HOST, APP_VERSION} from './consts';
import {clearToken, getToken} from '@shared/utils/token';
import {AuthRepository} from '@core/authentication/auth-repository.service';
import {AuthenticationService} from '@core/authentication/authentication.service';
import {clearWhoami} from '@shared/utils/whoami';
import {Router} from '@angular/router';

@Injectable()
export class AppInterceptorService implements HttpInterceptor {
    private authUsername = 'tenis';
    private authPassword = 'secret';

    constructor(private toastr: ToastrService, private authService: AuthRepository, private router: Router) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        req = this.addAppVersionHeaders(req);
        return next.handle(this.extendRequestHeaders(req)).pipe(
            catchError(err => {
                if (err.status === 401) {
                    if (err.error.error === 'invalid_token') {
                        window.location.reload();
                    }
                }

                if (err.status >= 200 && err.status < 300) {
                    const res = new HttpResponse({
                        body: null,
                        headers: err.headers,
                        status: err.status,
                        statusText: err.statusText,
                        url: err.url
                    });

                    return observableOf(res);
                }

                if (err.status === 500) {
                    this.toastr.error('Spróbuj ponownie później', 'Błąd serwera');
                }

                if (err.status === 403 && err.error.error === 'WYMAGANA AKTUALIZACJA') {
                    this.toastr.error('Wymagana aktualizacja aplikacji - odśwież stronę lub wyczyść dane przeglądarki');
                }

                return observableThrowError(err);
            })
        );
    }

    private extendRequestHeaders(req: HttpRequest<any>): HttpRequest<any> {
        // if url == login lub refresh token - add login headers
        const url = req.url;

        // pobieranie listy kortów
        if (url.includes('/court/all')) {
            if (this.tokenIsActive()) {
                return this.addOauthHeaders(req);
            } else {
                return req;
            }
        }

        if (url.includes('/oauth/token')) {
            return this.addLoginHeaders(req);
        }
        // if whoami dodaj oauth headers
        if (url.includes('whoami')) {
            return this.addOauthHeaders(req);
        }

        if (url.includes('myMatches')) {
            return this.addOauthHeaders(req);
        }

        // if !userExists nie dodawaj nagłówków lub jeśli token wygasł
        if (!getToken() || !this.tokenIsActive()) {
            return req;
        }

        // if request type === POST - dodaj post headers
        if (req.method === 'POST' || req.method === 'PUT') {
            return this.addPostHeaders(req);
        }

        // else add token
        return this.addToken(req, this.getOauthToken());
    }

    private tokenIsActive(): boolean {
        const expiresAt = +localStorage.getItem('expires_at');
        return expiresAt > Date.now();
    }

    private addToken(req: HttpRequest<any>, token: Token): HttpRequest<any> {
        let headers = req.headers;
        headers = headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        headers = headers.append('Authorization', `Bearer ${token.access_token}`);
        headers = headers.append('Content-Type', 'application/json');

        return req.clone({headers});
    }

    private getOauthToken(): Token {
        const token: Token = getToken();
        return token ? token : new Token();
    }

    public addOauthHeaders(req: HttpRequest<any>) {
        const token: Token = getToken();
        if (!token) {
            clearToken();
            clearWhoami();
            return req;
        }
        let headers = req.headers;
        headers = headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        headers = headers.append('Authorization', 'Bearer ' + token.access_token);
        return req.clone({headers});
    }

    public addPostHeaders(req: HttpRequest<any>) {
        const token: Token = getToken();
        let headers = req.headers;
        headers = headers.append('Authorization', 'Bearer ' + token.access_token);
        return req.clone({headers});
    }

    private addLoginHeaders(req: HttpRequest<any>) {
        let headers = req.headers;
        headers = req.headers;
        headers = headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        headers = headers.append('Authorization', 'Basic ' + btoa(this.authUsername + ':' + this.authPassword));
        return req.clone({headers});
    }

    private addAppVersionHeaders(req: HttpRequest<any>) {
        let headers = new HttpHeaders();
        // @ts-ignore
        const testInfo = API_HOST === 'https://api.tenis4u.pl' ? '' : ' (test)';
        headers = headers.append('X-Tenis-User-Agent', `tenis4u-web-frontoffice/${APP_VERSION}${testInfo}`);
        return req.clone({headers});
    }
}
