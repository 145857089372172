import {CourtData} from '@services/court/map-data.service';

export class MapCourt {
    constructor(
        public id: number,
        public name: string,
        public marker: string,
        public pricing: string,
        public reservation: boolean,
        public multisport: boolean,
        public medicover: boolean,
        public amountForFixedHour: number = null,
        public sportsType: string[] = [],
    ) {
    }

    public multiple = false;
    public additionalCourts: MapCourt[] = [];
    additionalThumbs: string[];

    static buildBasedOnCourtData(value: CourtData) {
        return new MapCourt(
            value.id,
            value.name,
            value.marker,
            mapCost(+value.min_cost, +value.max_cost),
            value.reservationAvailable,
            value.multisport_available,
            value.medicover_available,
            value.amount_for_fixed_hour ? +value.amount_for_fixed_hour : null,
            value.sports_type
        );
    }
}

const mapCost = (min: number, max: number): string => {
    if (min === 0 && min === 0) {
        return '-';
    }
    if (min === max) {
        return `${Number(min).toFixed(2).toString()}zł`;
    }
    return `${Number(min).toFixed(2).toString()}zł - ${Number(max).toFixed(2).toString()}zł`;
};
