export const TournamentImgPipeLogic = (tournamentType: string): string => {

        // <span *ngIf="type === 'SINGLE_CUP' || type === 'CUP'">Pucharowy</span>
        // <span *ngIf="type === 'PLAYOFF_CUP'">Grupowy + pucharowy</span>
        // <span *ngIf="type === 'CONSOLATION_CUP'">Pucharowy + pocieszenia</span>
        // <span *ngIf="type === 'LEAGUE'">Liga</span>
        // <span *ngIf="type === 'CHALLENGE'">Challenge</span>
            // challenge.png
            //     cup.png
            //     league.png
            //     playoff.png
            //     pocieszenia.png
        switch (tournamentType) {
            case 'SINGLE_CUP':
            case 'CUP':
                return '/assets/images/tournament2/cup.png';
            case 'PLAYOFF_CUP':
                return '/assets/images/tournament2/playoff.png';
            case 'CONSOLATION_CUP':
                return '/assets/images/tournament2/pocieszenia.png';
            case 'LEAGUE':
                return '/assets/images/tournament2/league.png';
            case 'CHALLENGE':
                return '/assets/images/tournament2/challenge.png';
            default:
                return '/assets/images/tournament2/cup.png';
        }
};
